<template>
  <b-badge pill
    class="ag-grid-cell-chip"
    :color="chipColor(params.value)"
  >
    <span>{{ params.value }}</span>
  </b-badge>
</template>

<script>
export default {
  name: 'CellRendererStatus',
  computed: {
    chipColor() {
      return value => {
        if (value === 'active') return 'success'
        if (value === 'blocked') return 'danger'
        if (value === 'deactivated') return 'warning'
        return 'primary'
      }
    },
  },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
  &.vs-chip-success {
    background: rgba(var(--vs-success), 0.15);
    color: rgba(var(--vs-success), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-warning {
    background: rgba(var(--vs-warning), 0.15);
    color: rgba(var(--vs-warning), 1) !important;
    font-weight: 500;
  }
  &.vs-chip-danger {
    background: rgba(var(--vs-danger), 0.15);
    color: rgba(var(--vs-danger), 1) !important;
    font-weight: 500;
  }
}
</style>

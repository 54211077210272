
<template>
  <div class="self-center">
    <b-badge
      v-if="params.active"
      pill
      class="h-6"
      variant="success"
    >
      Active
    </b-badge>
    <b-badge
      v-else
      pill
      class="h-6"
      variant="danger"
    >
      Deactivated
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'CellRendererCompanyStatus',
  props: {
    params: Object,
  },
}

</script>

<style lang="scss" scoped>

</style>

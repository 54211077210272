<template>
  <div class="d-flex justify-content-start">
    <!-- <b-avatar :src="params.data.image" class="flex-shrink-0 mr-2" size="40px" @click="$router.push(url)" /> -->
    <router-link
      :to="url"
      class="text-inherit text-dark hover:text-primary"
      @click.stop.prevent
    >
      {{ params.value }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    url() {
      return `/companies-manager/${ this.params.data['_id'] }`
    },
  },

  mounted() {
    // console.log(this.params)
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

a:hover {
  color: $primary !important;
}
</style>
<template>
  <div id="page-user-list">
    <b-modal
      v-model="edit_popup"
      size="lg"
      centered
      hide-footer
      :title="
        edit_section == 'edit'
          ? 'Edit User Details'
          : 'Create Corporate Account'
      "
    >
      <div class="vx-col w-full">
        <b-row v-if="false"> <!-- hide account_type for now and default to employer -->
          <b-col class="mx-1">
            <h6>Account Type</h6>
            <div class="d-flex">
              <b-form-radio
                v-model="edit_data.account_type"
                name="some-radios"
                value="employer"
                class="mr-1"
              >
                Employer Account
              </b-form-radio>

              <b-form-radio
                v-model="edit_data.account_type"
                name="some-radios"
                value="partner"
                class="ml-1"
              >
                Partner Account
              </b-form-radio>
            </div>
          </b-col>
        </b-row>

        <!-- Field: company_name -->
        <b-row class="mx-1 mt-2">
          <b-form-row class="w-100 mb-1">
            <b-col class="">
              <!-- <v-select
                id="register-account-company"
                v-model="edit_data.company_name"
                placeholder="Company Name"
                :options="all_companies"
                @search="handleJobsInput"
              /> -->

              <CompanySearch
                v-if="
                  $store.state.auth.ActiveUser.account_type === this.$AccountTypes.AGENCY
                "
                id="register-account-company"
                v-model="edit_data.company_id"
                :validator="true"
                name="register-account-company"
                :multiple="false"
                :placeholder="'Start typing to get company suggestions'"

                @company-selected="selectedCompanies"
              />

              <!-- <b-form-input
                v-model="edit_data.company_name"
                class="w-full"
                placeholder="Users Full Name"
                :state="$checkFullName(edit_data.company_name).state"
              /> -->
              <b-form-invalid-feedback tooltip>
                Please enter a valid company name.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <!-- Field: fullname
        <b-row class="mx-1 mt-2">
          <b-form-row class="w-100 mb-2">
            <b-col>
              <b-form-input
                v-model="edit_data.fullname"
                class="w-full"
                placeholder="Users Full Name"
                :state="$checkFullName(edit_data.fullname).state"
              />
              <b-form-invalid-feedback tooltip>
                {{ $checkFullName(edit_data.fullname).message }}
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row> -->

        <!-- Field: fullname -->
        <b-row class="mx-1 mt-2">
          <b-form-row class="w-100 mb-1">
            <b-col class="">
              <b-form-input
                v-model="edit_data.fullname"
                class="w-100"
                :state="$checkFullName(edit_data.fullname).state"
                danger-text="Please enter a valid key personnel/corporate administrator fullname"
                placeholder="Key Personnel/Corporate Administrator FullName"
              />
              <b-form-invalid-feedback tooltip>
                Please enter a valid key personnel/corporate administrator
                fullname.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <!-- Field: official_nname -->
        <!-- <b-row class="mx-1 mt-2">
          <b-form-row class="w-100 mb-1">
            <b-col class="">
              <b-form-input
                v-model="edit_data.official_name"
                class="w-100"
                :state="$checkOfficialName(edit_data.official_name).state"
                danger-text="Please enter a valid key personnel/corporate administrator fullname"
                placeholder="Key Personnel/Corporate Administrator FullName"
              />
              <b-form-invalid-feedback tooltip>
                Please enter a valid key personnel/corporate administrator
                fullname.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row> -->

        <!-- Field: email -->
        <b-row class="mx-1 mt-2">
          <b-form-row class="w-100 mb-1">
            <b-col class="">
              <b-form-input
                v-model="edit_data.email"
                class="w-100"
                :state="!email_check"
                danger-text="Please enter a valid key personnel/corporate administrator email address"
                placeholder="Key Personnel/Corporate Administrator Email Address"
              />
              <b-form-invalid-feedback tooltip>
                Please enter a valid key personnel/corporate administrator email
                address.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <!-- Field: phone_number -->
        <b-row class="mx-1 my-2">
          <b-form-row class="w-100 mb-1">
            <b-col class="">
              <b-form-input
                v-model="edit_data.phone_number"
                type="number"
                :formatter="$limitPhoneNumber"
                class="w-100"
                :state="$checkPhoneNumber(edit_data.phone_number).state"
                danger-text="Please enter a valid key personnel/corporate administrator phone number"
                placeholder="Key Personnel/Corporate Administrator Phone Number"
              />
              <b-form-invalid-feedback tooltip>
                Please enter a valid key personnel/corporate administrator phone
                number.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>
        <b-row
          v-if="
            this.$store.state.auth.ActiveUser.account_type === this.$AccountTypes.EDUCATION_INSTITUTION

          "
          class="mx-1 my-2"
        >
          <b-form-input
            v-if="
              $store.state.auth.ActiveUser.company_name.toLowerCase().includes('persolkelly')
            "
            v-model="edit_data.recruiter_number"
            class="w-full"
            placeholder="Recruiter Licence Number"
          />
          <b-form-input
            v-else
            v-model="edit_data.recruiter_number"
            class="w-full"
            placeholder="Recruiter Registration Number"
          />
        </b-row>

        <!-- Field: organization -->
        <b-row
          v-if="edit_data.company_name ? edit_data.company_name.toLowerCase().includes('persolkelly') : false"
          class="mx-1 my-2 w-100"
        >
          <v-select
            v-model="edit_data.organization"
            class="selectExample w-100 cursor-pointer"
            placeholder="Select an organization"
            :options="employers"
            label="value"
          />
        </b-row>

        <!-- Field: agency_number -->
        <b-row
          v-if="edit_data.company_name ? edit_data.company_name.toLowerCase().includes('persolkelly') : false"
          class="mx-1 my-2 w-100"
        >
          <b-form-input
            v-model="edit_data.agency_number"
            class="w-100"
            placeholder="EA License Number"
          />
        </b-row>

        <!-- Field: view_all -->
        <b-row
          v-if="edit_data.company_name ? edit_data.company_name.toLowerCase().includes('persolkelly') : false"
          class="mx-1 my-2"
        >
          <b-form-checkbox
            v-model="edit_data.view_all"
            class="w-full"
          >Allow view all data</b-form-checkbox>
        </b-row>

        <!-- Field: whitelist -->
        <b-row
          v-if="edit_data.company_name ? edit_data.company_name.toLowerCase().includes('persolkelly') : false"
          class="mx-1 my-2"
        >
          <b-form-checkbox
            v-model="edit_data.whitelist"
            class="w-full"
          >Whitelist user for telegram</b-form-checkbox>
        </b-row>

        <!-- Field: two_factor -->
        <!-- <b-row class="mx-1 my-1">
          <b-form-checkbox
            v-model="edit_data.two_factor"
            class="w-full"
          >Turn on two-factor authentication</b-form-checkbox>
        </b-row> -->

        <hr>

        <!-- show pass -->
        <b-row class="mx-1">
          <b-col
            v-if="edit_section !== 'edit'"
            cols="12"
            class="mx-1 my-1"
          >
            <b-form-checkbox
              v-model="edit_data.send_through_system"
              class="w-full"
            >Send login details through HeadHunters HQ system</b-form-checkbox>
          </b-col>
          <b-col
            cols="12"
            class="w-100 mb-1"
          >
            <b-button
              variant="warning"
              class="w-100"
              @click="generatePass"
            >
              Generate new password
            </b-button>
          </b-col>

          <b-col
            v-if="!edit_data.send_through_system && show_pass"
            cols="12"
            class="w-100 mb-1"
          >
            <b-form-input
              v-model="generated_pass"
              :class="`w-full`"
              @click="copyTextToClipboard()"
            />
            <b-alert
              class="mt-1 mx-1"
              variant="warning"
            >
              <div class="alert-body">
                <feather-icon
                  class="mr-25"
                  icon="InfoIcon"
                />
                <span
                  class="ml-25"
                >Please copy the new generated password because it wont be
                  available once you close this screen.</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <hr>

        <b-row class="mx-1 mb-1">
          <h6>Choose the role for this user</h6>
          <b-form-row class="w-100">
            <b-col class="">
              <!-- <b-form-select
                v-model="edit_data.organization"
                placeholder=""
                class="w-100 "
                :state="!email_check"
                :options="employers"
                label="value"
              /> -->

              <v-select
                id="role"
                ref="role"
                v-model="selected_role_id"
                :placeholder="
                  edit_data.role !== undefined
                    ? edit_data.role
                    : 'Pick a role or assign permissions to this user'
                "
                class="selectExample w-full"
                :options="roleOptions"
                :reduce="(role) => role.value"
                label="text"
                @input="repopulatePermissions"
              />

              <b-form-invalid-feedback tooltip>
                Please select a valid role.
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </b-row>

        <div :style="`min-height: 40vh;`">
          <permissions-card
            v-if="renderPermissionsCard"
            :user-permissions="edit_data.permissions"
            @change="handlePermissions"
          />
        </div>

        <hr>

        <div class="mx-1">
          <div class="vx-col w-full">
            <b-button
              class="mr-3 mb-2"
              variant="primary"
              @click="saveChanges"
            >{{
              edit_section == "edit" ? "Save Changes" : "Add Company"
            }}</b-button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-card class="">
      <b-row
        align-h="end"
        class="mr-0 w-80"
        :class="{'m-0 mt-1 w-100': isMobile}"
      >
        <b-col
          col
          cols="12"
          md="auto"
          class="mt-1 mt-md-0 px-0 px-sm-1"
        >
          <b-dropdown
            :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 180px' }`"
            split
            :text="action_text"
            right
            :variant="action_view"
            :icon="action_icon"
            type="filled"
            class="cursor-pointer"
            @click="performAction"
          >
            <b-dropdown-item @click="changeAction('Actions')">
              <span class="d-flex justify-content-start">
                <feather-icon
                  icon="InfoIcon"
                  svg-classes="h-4 w-4"
                  class="mr-1"
                />
                <span class="w-32">Actions</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$checkRights(this.$route.meta.section, 'write')"
              @click="changeAction('Add Client')"
            >
              <span class="d-flex justify-content-start">
                <feather-icon
                  icon="PlusIcon"
                  svg-classes="h-4 w-4"
                  class="mr-1"
                />
                <span class="w-32">Add Client</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col
          col
          cols="12"
          md="auto"
          class="mt-1 mt-md-0 px-0 px-sm-1"
        >
          <b-button
            variant="primary"
            class=""
            :style="`height: 40px; ${ isMobile ? 'width: 100%' : 'min-width: 150px' }`"
            @click="exportToCSV"
          >Export as CSV</b-button>
        </b-col>
      </b-row>

      <!-- AgGrid Table -->
      <div
        class="p-1"
        style="min-width: 50vw; min-height: 20vw;width: 100%; height: 100%;"
      >
        <vue-good-table
          mode="remote"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :rows="dataRows"
          :columns="columnDefs"

          style-class="vgt-table condensed bordered"
          compact-mode
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'company_status'">
              <CellRendererCompanyStatus :params="{active: props.row.active}" />
            </span>

            <span v-else-if="props.column.field === 'actions'">

              <CellRendererActions
                :params="{
                  company_name: props.row.company_name,
                  user_id: $store.state.auth.ActiveUser.id,
                  id: props.row.id
                }"
                @refresh-record="get_companies()"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>

              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import { onResizeMixins } from '@/components/mixins/onResizeMixins'
import { VueGoodTable } from 'vue-good-table'
// import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCard,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  VBTooltip,
  BOverlay,
  BFormCheckbox,
  BAlert,
  BFormInvalidFeedback,
  BPagination,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BFormRow,
} from 'bootstrap-vue'

// Store Module
import moduleUserManagement from '@/store/user-management/moduleUserManagement.js'

import CompanySearch from '@/components/CompanySearch.vue'
import PermissionsCard from '@/views/user-management-system/components/PermissionsCard.vue'
import { debounce } from 'lodash'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererStatus from './cell-renderer/CellRendererStatus.vue'
import CellRendererVerified from './cell-renderer/CellRendererVerified.vue'
import CellRendererCompanyStatus from './cell-renderer/CellRendererCompanyStatus.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

export default {
  components: {
    VueGoodTable,

    BCard,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BOverlay,
    BFormCheckbox,
    BAlert,
    BDropdown,
    BDropdownItem,
    BFormRow,
    BFormInvalidFeedback,

    vSelect,

    CompanySearch,
    PermissionsCard,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererCompanyStatus,
    CellRendererActions,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      // permissions
      renderPermissionsCard: true,
      selected_role_id: null,
      roleOptions: [],

      rowHeight: null,
      select_company: '',
      employers: [
        {
          text: 'P-SERV PTE LTD',
          value: 'P-SERV PTE LTD#199002727Z',
        },
        {
          text: 'PERSOLKELLY SINGAPORE PTE. LTD.',
          value: 'PERSOLKELLY SINGAPORE PTE. LTD.#199103521E',
        },
      ],
      blur_check: 'blur',
      email_check: true,
      edit_section: 'edit',
      section: {},
      perm: {},
      show_pass: false,
      context: null,
      generated_pass: '',
      edit_data: {
        fullname: '',
        email_address: '',
        team: '',
        company: '',
        recruiter_number: '',
        agency_number: '',
        two_factor: false,
        organization: '',
        whitelist: false,
        view_all: false,
        send_through_system: false,
      },
      all_companies: [],
      edit_popup: false,
      action_view: 'dark',
      action_text: 'Actions',
      action_icon: 'info_outline',

      // Filter Options
      companiesData: [],
      searchQuery: '',

      permissionsData: [
        {
          module: 'Analytics',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Data Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'Events Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },

        {
          module: 'Audit Logs',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
        {
          module: 'User Manager',
          read: true,
          write: true,
          create: true,
          delete: true,
        },
      ],
      isLoading: false,
      totalRecords: 0,
      dataRows: [],

      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true,
      },
      columnDefs: [
        {
          label: 'UEN Number',
          field: 'company_uen_no',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
          // pinned: true,
        },
        {
          label: 'Official Company Name',
          field: 'company_name',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Company Status',
          field: 'company_status',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',

            filterDropdownItems: [
              { value: 'active', text: 'Active' },
              { value: 'deactivated', text: 'Deactivated' },
            ],
          },
          cellRendererFramework: 'CellRendererCompanyStatus',
        },
        // {
        //   label: 'Deactivated At',
        //   field: 'deactivated_at',
        //   filter: true,
        //   width: 150,
        // },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'text-right',
          tdClass: 'text-right',
          cellRendererFramework: 'CellRendererActions',
        },
      ],

      // Cell Renderer Components
      components: {
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },

      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 10,
      },
      exportingCSVIndex: null,
      exportingCSV: false,
      selectedRows: null,
    }
  },
  watch: {
    edit_popup(val) {
      if (!val) {
        this.edit_data = {
          fullname: '',
          email_address: '',
          team: '',
          company: '',
          recruiter_number: '',
          agency_number: '',
          two_factor: false,
          whitelist: false,
          organization: '',
          view_all: false,
          send_through_system: false,
        }
        this.perm = {}
        this.section = {}
        this.show_pass = false
        this.generated_pass = ''
        this.edit_section = 'edit'
        this.get_companies()
      }
    },
    'edit_data.email': function (val, oldVal) {
      if (val.includes('@') && val.includes('.')) {
        this.email_check = false
      } else {
        this.email_check = true
      }
    },
    'edit_data.organization': function (val, oldVal) {
      if (val.startsWith('P-SERV')) {
        if (this.edit_data.agency_number.length < 3) {
          this.edit_data.agency_number = '90C3494'
        } else if (this.edit_data.agency_number == '01C4394') {
          this.edit_data.agency_number = '90C3494'
        }
      } else if (this.edit_data.agency_number.length < 3) {
        this.edit_data.agency_number = '01C4394'
      } else if (this.edit_data.agency_number == '90C3494') {
        this.edit_data.agency_number = '01C4394'
      }
      // this.$forceUpdate();
    },
  },
  beforeMount() {
    this.context = { componentParent: this }
  },
  created() {
    this.rowHeight = 20
    if (this.check_rights('read')) {
      // if (this.$store.state.auth.ActiveUser.company_name === 'PersolKelly') {
      //   const custom_column = {
      //     label: 'Team',
      //     field: 'team',
      //     filter: true,
      //     width: 200,
      //   }
      //   this.columnDefs.splice(2, 0, custom_column)
      // }
      this.blur_check = ''
      if (!moduleUserManagement.isRegistered) {
        this.$store.registerModule('userManagement', moduleUserManagement)
        moduleUserManagement.isRegistered = true
      }
      this.get_companies()
    } else {
      this.blur_check = 'blur'
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Inadequate permission',
            icon: 'CheckCircleIcon',
            text: 'You dont have enough rights to view the data in this section',
            variant: 'danger',
            position: 'center',
          },
        },
        { timeout: this.$longestTimeout },
      )
    }

    this.get_roles()
  },
  methods: {
    handleJobsInput: debounce(function (text) {
      this.all_companies = []
      this.getEmployers(text)
    }, 1000),
    selectedCompanies(company) {
      this.edit_data.id = company.id
      this.edit_data.uen_no = company.company_uen_no
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.get_companies()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    get_companies() {
      return new Promise((resolve, reject) => {
        const { token } = this.$store.state.auth.ActiveUser

        this.$http.defaults.headers.common.Authorization = `Token ${token}`
        this.isLoading = true

        // server pagination
        let query = ''
        if (this.exportingCSV) {
          this.serverParams.extractCSV = true

          if (!this.$isEmpty(this.selectedRows)) {
            const selectedRowsIdArray = []
            this.selectedRows.forEach(row => {
              selectedRowsIdArray.push(row.id)
            })

            this.serverParams.selectedRows = selectedRowsIdArray
          }
        }

        query = `?params=${JSON.stringify(this.serverParams)}`

        this.$http
          .get(`/api/companies${query}`, {
            onDownloadProgress(progressEvent) {
              if (this.exportingCSV) {
                const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                const parent = document.querySelector('#exportToCSV')
                parent.querySelector('.progress-bar').style = `width: ${uploadPercentage}%;`
              }
            },
          })
          .then(response => {
            if (this.exportingCSV) {
              const headers = []

              this.columnDefs.forEach(column => {
                if (column.label.toLowerCase() === 'actions') {
                  return
                }
                headers.push({
                  label: column.label,
                  columnName: column.field,
                })
              })
              // headers.push({
              //   label: 'ID',
              //   columnName: '_id',
              // })
              const csvData = response.data.companies
              csvData.forEach(item => {
                const newItem = item
                if (newItem.company_status) {
                  newItem.company_status = 'active'
                } else {
                  newItem.company_status = 'deactivated'
                }
                return newItem
              })

              this.$downloadCSV(csvData, `Client - ${this.$formatDatetime(new Date())}.csv`, headers)
              this.exportingCSV = false
              this.serverParams.extractCSV = false
            } else {
              this.dataRows = response.data.companies
              this.pagination = response.data.pagination
              this.totalRecords = response.data.totalRecords
            }

            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${100}%;`

              setTimeout(() => {
                this.$toast.dismiss(this.exportingCSVIndex)
              }, this.$longestTimeout)
            }

            this.isLoading = false

            resolve()
          })
          .catch(() => {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Operation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Could not retrieve the company user list. Please try again later!',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )

            const parent = document.querySelector('#exportToCSV')
            if (parent) {
              parent.querySelector('.progress-bar').style = `width: ${100}%;`

              setTimeout(() => {
                this.$toast.dismiss(this.exportingCSVIndex)
              }, this.$longestTimeout)
            }

            this.isLoading = false

            reject()
          })
      })
    },
    validateCompanyFormData(form_data) {
      let message = ''

      // if (this.$isEmpty(form_data.account_type)) {
      //   message += 'Please select an Account Type\n'
      // }

      if (this.$isEmpty(form_data.id)) {
        message += 'Please select a Company Name\n'
      }

      if (this.$isEmpty(form_data.super_user_info.fullname)) {
        message += 'A valid Full Name is required\n'
      }
      if (this.$isEmpty(form_data.super_user_info.phone_number)) {
        message += 'A valid Phone Number is required\n'
      }

      if (this.email_check) {
        message += 'A valid Email is required\n'
      }

      return message
    },

    post_company() {
      const form_data = {}
      // if (!this.$isEmpty(this.edit_data.company_name)) {
      //   form_data.name = this.edit_data.company_name.split('#')[0]
      //   form_data.company_name = this.edit_data.company_name.split('#')[0]
      // }
      form_data.id = this.edit_data.id
      form_data.uen_no = this.edit_data.uen_no

      // form_data.official_name = this.edit_data.official_name
      form_data.agency_number = this.edit_data.agency_number
      form_data.company_image = 'default'
      form_data.other_name = []
      form_data.primary_ssic = 'NA'
      form_data.secondary_ssic = 'NA'

      form_data.whitelist = this.edit_data.whitelist
      form_data.recruiter_number = this.edit_data.recruiter_numberu
      form_data.view_all = this.edit_data.view_all
      form_data.agency_number = this.edit_data.agency_number
      form_data.organization = this.edit_data.organization

      form_data.parent_company_id = this.$store.state.auth.ActiveUser.company_id
      form_data.role_id = this.selected_role_id !== 'custom' ? this.selected_role_id : null
      form_data.super_user_info = {
        fullname: this.edit_data.fullname,
        // account_type: this.edit_data.account_type,
        account_type: 'employer', // ! hide account_type for now and default to employer
        phone_number: this.edit_data.phone_number,
        email_address: this.edit_data.email,
        password: this.generated_pass,
        company_id: this.edit_data.company_id,

        // two_factor: this.edit_data.two_factor,
        send_through_system: this.edit_data.send_through_system,
        role_id: this.selected_role_id !== 'custom' ? this.selected_role_id : null,
        permissions: this.edit_data.permissions,

        whitelist: this.edit_data.whitelist,
        recruiter_number: this.edit_data.recruiter_numberu,
        view_all: this.edit_data.view_all,
        agency_number: this.edit_data.agency_number,
        organization: this.edit_data.organization,
      }

      const validationMessage = this.validateCompanyFormData(form_data)
      if (this.$isEmpty(validationMessage)) {
        this.$http
          .post('/api/companies', {
            company_info: form_data,
          })
          .then(response => {
            if (response.data.success) {
              this.$toastSuccess('Operation Status', 'Client created successfully!')

              // if (this.edit_section === 'edit') {
              //   this.updateUsersData(this.generated_pass)
              // } else {
              //   this.createUserAccount()
              // }

              this.edit_popup = false
            } else {
              this.$toastDanger('Something went wrong', response.data.message)
            }
          })
          .catch(error => {
            this.$handleErrorResponse(error)
          })
      } else {
        this.$toastWarning('Incomplete Form', validationMessage)
      }
    },
    // post_company() {
    //   const form_data = {}
    //   if (!this.$isEmpty(this.edit_data.company_name)) {
    //     form_data.name = this.edit_data.company_name.split('#')[0]
    //     form_data.company_name = this.edit_data.company_name.split('#')[0]
    //     form_data.uen_no = this.edit_data.company_name.split('#')[1]
    //   }
    //   form_data.company_id = this.edit_data.company_id
    //   form_data.account_type = 'employer' // ! hide account_type for now and default to employer
    //   // form_data.account_type = this.edit_data.account_type

    //   // form_data.official_name = this.edit_data.official_name
    //   form_data.agency_number = this.edit_data.agency_number
    //   form_data.phone_number = this.edit_data.phone_number
    //   form_data.company_image = 'default'
    //   form_data.other_name = []
    //   form_data.primary_ssic = 'NA'
    //   form_data.secondary_ssic = 'NA'

    //   form_data.fullname = this.edit_data.fullname
    //   form_data.email_address = this.edit_data.email
    //   form_data.password = this.generated_pass
    //   // form_data.two_factor = this.edit_data.two_factor
    //   form_data.send_through_system = this.edit_data.send_through_system
    //   form_data.role_id = this.selected_role_id
    //   form_data.permissions = this.edit_data.permissions

    //   form_data.whitelist = this.edit_data.whitelist
    //   form_data.recruiter_number = this.edit_data.recruiter_numberu
    //   form_data.view_all = this.edit_data.view_all
    //   form_data.agency_number = this.edit_data.agency_number
    //   form_data.organization = this.edit_data.organization
    //   form_data.super_user_info = {
    //     fullname: this.edit_data.fullname,
    //     // account_type: this.edit_data.account_type,
    //     account_type: 'employer', // ! hide account_type for now and default to employer
    //     phone_number: this.edit_data.phone_number,
    //     password: this.generated_pass,
    //     company_id: this.edit_data.company_id,
    //   }

    //   const validationMessage = this.validateCompanyFormData(form_data)
    //   if (this.$isEmpty(validationMessage)) {
    //     this.$http
    //       .post('/api/companies', {
    //         company_info: form_data,
    //       })
    //       .then(response => {
    //         if (response.data.success) {
    //           this.$toastSuccess('Operation Status', 'Client created successfully!')

    //           // if (this.edit_section === 'edit') {
    //           //   this.updateUsersData(this.generated_pass)
    //           // } else {
    //           //   this.createUserAccount()
    //           // }

    //           this.edit_popup = false
    //         } else {
    //           this.$toastDanger('Something went wrong', response.data.message)
    //         }
    //       })
    //       .catch(error => {
    //         this.$handleErrorResponse(error)
    //       })
    //   } else {
    //     this.$toastWarning('Incomplete Form', validationMessage)
    //   }
    // },

    get_roles() {
      this.$http
        .get('/api/roles')
        .then(response => {
          const { roles } = response.data

          roles.forEach(role => {
            this.roleOptions.push({
              text: role.name,
              value: role.id,
            })
          })
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },

    check_rights(right) {
      // let checker = (arr, target) => target.every((v) => arr.includes(v));
      const { permissions } = this.$store.state.auth.ActiveUser
      const currentPagePermission = permissions.filter(item => item.section === 'User Manager')[0]
      const currentPermission = currentPagePermission[right]
      if (currentPermission) {
        return true
      }
      return true
    },

    copyTextToClipboard() {
      const text = this.generated_pass
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        () => {},
        err => {},
      )
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Copy Operation',
            icon: 'CheckCircleIcon',
            text: 'Text copied successfully',
            variant: 'success',
          },
        },
        { timeout: this.$longestTimeout },
      )
    },

    deleteSelected() {
      this.get_companies()
        .then(() => {
          this.adjustAgGridSize()
        })
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (
        c
          ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16))
    },

    generatePass() {
      const uniq = this.uuidv4()
      this.generated_pass = uniq
      this.show_pass = true
    },

    saveChanges() {
      this.post_company()
    },

    updateUsersData(password, user_group = null, company_name = null) {
      const { token } = this.$store.state.auth.ActiveUser
      const email = this.edit_data.email_address.toLowerCase()
      this.$http.defaults.headers.common.Authorization = `Token ${token}`
      this.isLoading = true
      const data = {
        // two_factor: this.edit_data.two_factor,
        company_name: this.$store.state.auth.ActiveUser.company_name,
        whitelist: this.edit_data.whitelist,
        view_all: this.edit_data.view_all,
      }
      if (this.edit_data.organization !== undefined) {
        if (this.edit_data.organization.length > 3) {
          data.organization = this.edit_data.organization.split('#')[0]
          // this.select_company.split("#")[1],
        }
      }
      if (user_group) {
        data.user_group = user_group
      }

      if (company_name) {
        data.company_name = company_name
      }

      if (password) {
        data.password = password
      }
      if (this.edit_data.team) {
        data.team = this.edit_data.team
      }
      if (this.edit_data.fullname) {
        data.fullname = this.edit_data.fullname
      }

      if (this.edit_data.recruiter_number) {
        data.recruiter_number = this.edit_data.recruiter_number
      }

      if (this.edit_data.agency_number) {
        data.agency_number = this.edit_data.agency_number
      }
      this.$http
        .put(`/api/company-user/${email}`, {
          user: data,
        })
        .then(response => {
          const output = response.data
          if (output.success) {
            this.edit_popup = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Update Successful',
                  icon: 'CheckCircleIcon',
                  text: 'Your changes have been saved.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            // this.$toast(
            //   {
            //     component: ToastificationContent,
            //     props: {
            //       title: 'Update Failed',
            //       icon: 'CheckCircleIcon',
            //       text: 'Your changes have failed.',
            //       variant: 'danger',
            //     },
            //   },
            //   { timeout: this.$longestTimeout },
            // )
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Operation Failed',
                icon: 'AlertCircleIcon',
                text: 'Could not retrieve the company user list. Please try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },

    createUserAccount() {
      const {
        account_type,
        company_id,
        official_name,
        email,
        phone_number,
        recruiter_number,
        agency_number,
        two_factor,
        send_through_system,
        selected_role_id,
        permissions,
      } = this.edit_data

      const data = {
        account_type, // default
        company_id,
        fullname: official_name,
        email_address: email.toLowerCase(),
        phone_number,
        password: this.generated_pass,
        recruiter_number,
        agency_number,
        two_factor_active: two_factor,
        send_through_system,
        role_id: selected_role_id,
        permissions,
      }

      this.$http
        .post('/api/auth/register', {
          user: data,
        })
        .then(response => {
          const output = response.data
          if (output) {
            this.updateUsersData('', null, company_id)

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Account Creation Update',
                  icon: 'CheckCircleIcon',
                  text: `${output.email} account has been created successfully.`,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
          } else {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Account Creation Failed',
                  icon: 'CheckCircleIcon',
                  text: 'Please try again later.',
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Account creation failed',
                icon: 'CheckCircleIcon',
                text: error.response.data.errors.body[0],
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },

    changeAction(text) {
      this.action_text = text
      switch (text) {
        case 'Add Client':
          this.action_view = 'success'
          this.action_icon = 'add'
          break
        case 'Delete Client':
          this.action_view = 'danger'
          this.action_icon = 'delete'
          break
        case 'Actions':
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
          break
        default:
          this.action_view = 'dark'
          this.action_icon = 'info_outline'
      }

      this.performAction()
    },
    performAction() {
      if (this.action_text === 'Add Client') {
        if (this.check_rights('write')) {
          this.edit_popup = true
          this.edit_section = 'add'
          this.generated_pass = this.uuidv4()
          this.show_pass = true
        } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Inadequate permission',
                icon: 'CheckCircleIcon',
                text: 'You dont have enough rights to add data in this section',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )
        }
      }
    },

    onClickCloseButton() {
      this.$emit('closePopup')
    },

    createPerms(rights, accessibles) {
      const perms = {}
      const sections = {}
      for (const right of rights) {
        perms[right] = true
      }

      for (const section of accessibles) {
        sections[section] = true
      }

      this.perm = perms
      this.section = sections
    },

    methodFromParent(popup, edit_data) {
      this.edit_popup = popup
      this.edit_data = edit_data
      this.createPerms(edit_data.perm_rights, edit_data.perm_sections)
    },

    handleInput() {
      const text = document.getElementsByClassName(
        'input-select v-select--input',
      )[0].value
      this.getEmployers(text)
    },
    // getEmployers(query) {
    //   this.employers = []
    //   this.$http
    //     .get(`/api/company-search/${query}`)
    //     .then(response => {
    //       if (response.data.success) {
    //         for (const item of response.data.data) {
    //           const index = this.employers.findIndex(x => x.text == item.text)
    //           if (index === -1) {
    //             this.employers.push({
    //               text: item.text,
    //               value: item.value,
    //             })
    //           }
    //         }
    //       } else {
    //         this.event_exist = false
    //       }
    //     })
    //     .catch(error => {})
    // },

    // permission
    repopulatePermissions() {
      this.$http
        .get(`/api/roles/${this.selected_role_id}`)
        .then(response => {
          const role = response.data

          this.$delete(this.edit_data, 'permissions')
          this.$set(this.edit_data, 'permissions', role.permissions)
          this.$set(this.edit_data, 'role', role.name)
          this.$set(this.edit_data, 'role_id', role.id)

          // to force permissioncard to display the new permissions
          this.renderPermissionsCard = false
          setTimeout(() => {
            this.renderPermissionsCard = true
          }, 200)
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    handlePermissions(permissions) {
      this.edit_data.permissions = permissions
      this.selected_role_id = 'custom'
      this.$set(this.edit_data, 'role', 'custom')
    },

    exportToCSV() {
      // retrieve data from db
      this.exportingCSV = true

      this.exportingCSVIndex = this.$toast(
        {
          component: this.$toastContent,
          props: {
            title: 'Compiling CSV',
            icon: 'AlertCircleIcon',
            text: 'Please wait a moment',
            variant: 'warning',
            progress: 0,
            progressId: 'exportToCSV',
          },
        },
        { timeout: 99999999 },
      )

      this.get_companies()
    },
  },
}
</script>

<style lang="scss">
.blur {
  filter: blur(16px);
}

</style>
